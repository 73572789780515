// Override default variables before the import

@import './styles/vars.scss';

$body-bg: $overlay;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'overlay': $overlay,
  'mix': $mix,
  'grey': $grey,
  'dark': $dark,
  'light': $light,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($primary, 0.2);
}

::-webkit-scrollbar-track {
  background: rgba($primary, 0.1);
}

@import '~react-toastify/scss/main.scss';

:root {
  // Override toastify vars.
  --toastify-color-success: #497173;
  --toastify-color-error: #eb6440;
}

// body {
//   font-family: 'Roboto', sans-serif;
// }

// /* Override default colors */
// $body-bg: #eff5f5;
// $primary: #497173;
// $secondary: #d6e3e6;
// $light: #d6e3e6;

// /* Add new colors */
// $overlay: $body-bg;
// $mix: #eb6440;

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';

// /* Custom color map */
// $custom-colors: (
//   'overlay': $overlay,
//   'mix': $mix,
// );

// // Merge the maps
// $theme-colors: map-merge($theme-colors, $custom-colors);
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
// $utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
// $utilities-text-colors: map-loop(
//   $utilities-colors,
//   rgba-css-var,
//   '$key',
//   'text'
// );
// $utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

// /* Import bootstrap to set changes */
// @import '~bootstrap/scss/bootstrap';
