/* Extra small devices (portrait phones, less than 576px) */
.aside {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .aside {
    min-width: 40%;
    max-width: 40%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .aside {
    min-width: 30%;
    max-width: 30%;
  }
}

/* Extra extra large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
  .aside {
    min-width: 25%;
    max-width: 25%;
  }
}
