@import '../../../../styles/vars.scss';

.chat-item::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  border-bottom: 8px solid $secondary;
}

.chat-item-right::before {
  right: -6px;
  border-right: 8px solid transparent;
}

.chat-item-left::before {
  left: -6px;
  border-left: 8px solid transparent;
}

.fancy small:before,
.fancy small:after {
  content: '';
  position: absolute;
  border-top: 2px solid $light;
  top: 8px;
  width: 100%;
}
.fancy small:before {
  right: 100%;
  margin-right: 23px;
}
.fancy small:after {
  left: 100%;
  margin-left: 23px;
}
