.auth {
  margin: 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 90vh;
}

.auth span {
  margin-top: -1rem;
}

.auth-content {
  padding: 1rem 2rem 5rem;
  margin-top: 2rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .auth {
    min-height: 100vh;
    margin: 0;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
  .auth-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    width: 60%;
    padding: 4rem 2rem 7rem;
    margin-top: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .auth-content {
    width: 45%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .auth-content {
    width: 40%;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .auth-content {
    width: 30%;
  }
}
